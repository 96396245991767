<template>
  <div class="form-wrap background">
    <passwordRecovery
      v-if="displayPasswordRecovery"
      :accountEmail="accountEmail"
      @newPassword="submitPasswordReset($event)"
    ></passwordRecovery>
    <passwordResetSuccess
      v-if="displayPasswordResetSuccess"
    ></passwordResetSuccess>

  </div>
</template>


<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../components/firebase/firebaseInit.js";
import passwordRecovery from "../components/emailHandlers/passwordRecovery.vue";
import passwordResetSuccess from "../components/emailHandlers/passwordResetSuccess.vue";

export default {
  data() {
    return {
      // Get the action to complete.
      mode: null,
      // Get the one-time code from the query parameter.
      actionCode: null,
      // (Optional) Get the continue URL from the query parameter if available.
      continueUrl: null,
      // (Optional) Get the language code if available.
      lang: null,
      displayPasswordRecovery: false,
      displayPasswordResetSuccess: false,
      accountEmail: null,
    };
  },
  components: {
    passwordRecovery,
    passwordResetSuccess
  },
  methods: {
    verifyEmail() {
      let userId = this.$store.state.profileId;
      console.log(userId);
    },
    submitPasswordReset(newPassword) {
      firebase.auth().confirmPasswordReset(this.actionCode, newPassword.newPassword)
    .then(() => {
      console.log("PASSWORD RESET!")
      this.displayPasswordRecovery = false
      this.displayPasswordResetSuccess = true

    })
    .catch((e) => {
      console.log(e)
    })
    }
  },
  mounted() {
    this.mode = this.$route.query.mode;
    this.actionCode = this.$route.query.oobCode;
    this.continueUrl = this.$route.query.continueUrl;
    this.lang = this.$route.query.lang;
    const auth = firebase.auth();

    let handleVerifyEmail = async (auth, actionCode, continueUrl, lang) => {
      auth
        .applyActionCode(this.actionCode)
        .then(async(resp) => {
          // Email address has been verified.
          console.log("I HAVE RUN");
          const userdb = db
            .collection("users")
            .doc(this.$store.state.profileId);
          userdb
            .update({
              emailVerified: true,
            })
            .catch((error) => {
              console.log(error);
              console.log("I HAVE RUN");

            });

          await db.collection("users")
          .doc(this.$store.state.profileId)
          .get()
          .then(async (querySnapshot) => {
            this.$store.state.emailVerified = querySnapshot.data().emailVerified;
          });


          console.log("email verified");
          if (this.$store.state.userClaims.admin) {
            this.$router.push({ name: "Admin Panel" });
            console.log("Going to Admin Panel");
          } else if (this.$store.state.userClaims.broadcaster) {
            this.$router.push({ name: "Stream Browser" });
            console.log("Going to Stream Browser");
          } else if (this.$store.state.userClaims.viewer) {
            this.$router.push({ name: "Stream Browser" });
            console.log("Going to Stream Browser");
          } else {
            this.$router.push({ name: "Login" });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("I HAVE RUN");

          if (this.$store.state.profileId != null) {
            if (this.$store.state.userClaims.admin) {
            this.$router.push({ name: "Admin Panel" });
            console.log("Going to Admin Panel");
          } else if (this.$store.state.userClaims.broadcaster) {
            this.$router.push({ name: "Stream Browser" });
            console.log("Going to Stream Browser");
          } else if (this.$store.state.userClaims.viewer) {
            this.$router.push({ name: "Stream Browser" });
            console.log("Going to Stream Browser");
          }
          } else {
            this.$router.push({ name: "Login" });
          }
        });
    };

    let handleResetPassword = async (auth, actionCode, continueUrl, lang) => {
                console.log(this.actionCode);

      auth
        .verifyPasswordResetCode(this.actionCode)
        .then((email) => {
          this.displayPasswordRecovery = true;
          this.accountEmail = email;
        })
        .catch( (e) => {
          console.log(e)
        });
    };

    switch (this.mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        console.log("RESET PASSWORD")
        handleResetPassword(auth, this.actionCode, this.continueUrl, this.lang);
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        handleRecoverEmail(auth, this.actionCode, this.lang);
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        handleVerifyEmail(auth, this.actionCode, this.continueUrl, this.lang);

        break;
      default:
    }
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: end;
  width: 100%;
  margin: 0 auto;
  background-image: url("../assets/loginbackground.jpg");

  .background {
    background-size: cover;
    background-image: url("../assets/loginbackground.jpg");
  }
}
</style>