<template>
  <div class="registerModal">
    <div class="modalHeader">
      <!-- <div class="stepContainer">
        <div class="stepBlocks">
          <div class="stepNumber">
            <font-awesome-icon :icon="['fas', 'check']" />
          </div>
        </div>

        <div class="spacer"></div>

        <div class="stepBlocks activeStep">
          <div class="stepNumber activeStep">2</div>
        </div>

        <div class="spacer"></div>

        <div class="stepBlocks">
          <div class="stepNumber">3</div>
        </div>
      </div> -->
      <span>Reset your password</span>
      <span class="sub-header">for {{accountEmail}}.</span>
    </div>
    <div class="modalBody">
      <form class="register">
        <div class="inputs">
          <div class="input">
            <label>Enter Password</label>
            <div>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                name="password"
                v-model="v$.password.$model"
                v-bind:class="{ error: v$.password.$error }"
                @input="checkValidation"
              />

              <font-awesome-icon
                :icon="['fas', 'eye']"
                @click="unhidePassword"
              />
            </div>
          </div>
          <div class="input">
            <label>Confirm Password</label>
            <div style="width= 100%">
              <input
                id="confirmPassword"
                type="password"
                placeholder="Confirm your password"
                name="confirmPassword"
                v-model="v$.confirmPassword.$model"
                v-bind:class="{ error: v$.confirmPassword.$error }"
                @input="checkValidation"
              />
              <font-awesome-icon
                :icon="['fas', 'eye']"
                @click="unhideConfirmPassword"
              />
            </div>
          </div>
        </div>
        <div class="message">
          <span class="header"> Password Requirements </span>

          <ul style="list-style-type: none">
            <li>
              More than 8 characters
              <font-awesome-icon
                :class="{ iconError: lengthError }"
                :icon="['fas', 'minus-circle']"
              />
            </li>
            <li>
              Contains letters and numbers
              <font-awesome-icon
                :class="{ iconError: hasNumberLetterError }"
                :icon="['fas', 'minus-circle']"
              />
            </li>
            <li>
              Contains atleast 1 capital letter
              <font-awesome-icon
                :class="{ iconError: hasCapitalLetterError }"
                :icon="['fas', 'minus-circle']"
              />
            </li>
            <li>
              Contains atleast 1 symbol
              <font-awesome-icon
                :class="{ iconError: hasSpecialCharacterError }"
                :icon="['fas', 'minus-circle']"
              />
            </li>
            <li>
              Password and confirmation match
              <font-awesome-icon
                :class="{ iconError: sameAsError }"
                :icon="['fas', 'minus-circle']"
              />
            </li>
          </ul>
        </div>
        <div class="footer">
          <button @click.prevent="nextStep">CONTINUE</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "firebase/compat/auth";
import useVuelidate from "@vuelidate/core";
import { sameAs, minLength } from "@vuelidate/validators";
import {
  hasNumber,
  hasLowercaseLetter,
  hasCapitalcaseLetter,
  hasSpeciaCharacter,
} from "../../customValidators/customValidators.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

export default {
  name: "passwordRecovery",
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      userId: "",
      userRole: "",
      password: "",
      errorMsg: "",
      error: false,
      confirmPassword: "",
      lengthError: "iconError",
      hasNumberLetterError: "iconError",
      hasCapitalLetterError: "iconError",
      hasSpecialCharacterError: "iconError",
      sameAsError: "iconError",
    };
  },
  components: {
    FontAwesomeIcon,
  },
  props: ['accountEmail'
  ],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      password: {
        minLength: minLength(8),
        hasNumber,
        hasLowercaseLetter,
        hasCapitalcaseLetter,
        hasSpeciaCharacter,
      },
      confirmPassword: { sameAs: sameAs(this.password) },
    };
  },
  created() {
  },
  methods: {
    unhidePassword() {
      console.log("CLICK");
      let x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },

    unhideConfirmPassword() {
      let x = document.getElementById("confirmPassword");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    checkValidation() {
      if (
        this.v$.password.minLength.$response == false ||
        this.password == ""
      ) {
        this.lengthError = "iconError";
      } else {
        this.lengthError = "";
      }

      if (this.password == "") {
        this.hasNumberLetterError = "iconError";
      } else if (this.v$.password.hasNumber.$response == false) {
        this.hasNumberLetterError = "iconError";
      } else if (this.v$.password.hasLowercaseLetter.$response == false) {
        this.hasNumberLetterError = "iconError";
      } else {
        this.hasNumberLetterError = "";
      }

      if (
        this.v$.password.hasCapitalcaseLetter.$response == false ||
        this.password == ""
      ) {
        this.hasCapitalLetterError = "iconError";
      } else {
        this.hasCapitalLetterError = "";
      }

      if (
        this.v$.password.hasSpeciaCharacter.$response == false ||
        this.password == ""
      ) {
        this.hasSpecialCharacterError = "iconError";
      } else {
        this.hasSpecialCharacterError = "";
      }

      if (
        this.v$.confirmPassword.sameAs.$response == false ||
        this.confirmPassword == ""
      ) {
        this.sameAsError = "iconError";
      } else {
        this.sameAsError = "";
      }

      console.log(
        this.v$.password.hasNumber.$response &&
          this.v$.password.hasLowercaseLetter.$response == true
      );
    },
    async nextStep() {
      const isFormCorrect = await this.v$.$validate();
      let updatedPassword = {
        newPassword: this.password,
      };

      if (isFormCorrect == true) {
        this.$emit("newPassword", updatedPassword);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.registerModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  width: 542px;
  margin-left: auto;
  flex-shrink: 0;
  color: #e5ecff;
  background-color: #2f323c;

  .modalHeader {
    background-color: #2f323c !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    span {
      font-family: Roboto;
      font-weight: 300;
      font-size: 32px;
      color: #eff3ff;
    }

    .sub-header {
      font-size: 18px !important;
    }
     .stepContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
margin-bottom: 25px;

      .spacer {
        width: 50px;
        border-top: 3px dotted #444b5a;
      }

      .activeStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }

      .completedStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }
      .stepBlocks {
        background-color: inherit;
        border: #444b5a 2px solid;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .stepNumber {
          font-weight: 700;
          color: #444b5a;
          font-size: 25px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    input {
      height: 60px;
      background-color: #262830;
      border: 1px solid #17181a;
      margin: 10px 0px 15px 0px;
      color: #eff3ff;
      padding: 10px;
      width: 90%;
      margin-right: 10px;
    }

    div .input {
      display: flex;
      flex-direction: column;
    }

    .register {
width: calc(100% - 80px);
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 40px;
    }
  }

  .message {
display: flex;
    flex-direction: column;
    background-color: #444b5a;
    padding: 10px;
    color: #eff3ff;
    align-items: center;
    justify-items: space-around;
    font-size: 18px;
    margin-top: auto;

    span {
      text-align: center;
      font-weight: 400;
    }

    .header {
      margin-top: 10px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .link {
      margin-top: 10px;
      color: #b2c7ff;
    }

    ul {
      list-style-type: none;
      width: 80%;
      padding: 0;
    }

    li {
      display: flex;
      justify-content: space-between;
      line-height: 1.5em;
    }

    .iconError {
      color: #e93c2d;
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
    height: auto;
    width: 100%;
    margin-top: auto;

    button {
      background-color: #434b5a;
      border: none;
      height: 60px;
      width: 460px;
      color: #eff3ff;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    button:hover {
      background-color: #0084ff;
    }
  }
  input.error {
    background-color: #4d2121 !important;
    border: #ad0000 solid 1px !important;
    color: #ad0000 !important;
  }
}

.inputs {
  margin-top:auto;
}
</style>