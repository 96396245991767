<template>
  <div class="registerModal">
    <div class="modalBody">

      <div class="wrapper">
                    <font-awesome-icon
                :icon="['fas', 'check-circle']"
                size="8x"
              />
              <span id="saved"> Saved. </span>
              <span> Your password has been reset. </span>
              </div>
                      <div class="footer">
          <button @click.prevent="toLogin">CONTINUE</button>
        </div>
        
    </div>
  </div>
</template>

<script>
import "firebase/compat/auth";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

export default {
  name: "passwordResetSuccess",
  data() {
    return {
    };
  },
  components: {
    FontAwesomeIcon,
  },
  created() {
  },
  methods: {
    toLogin() {
      this.$router.push( '/');
    }
  },
};
</script>

<style lang="scss" scoped>
.registerModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  width: 542px;
  margin-left: auto;
  flex-shrink: 0;
  color: #e5ecff;
  background-color: #2f323c;

  .modalHeader {
    background-color: #2f323c !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    span {
      font-family: Roboto;
      font-weight: 300;
      font-size: 32px;
      color: #eff3ff;
    }

    .sub-header {
      font-size: 18px !important;
    }
     .stepContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
margin-bottom: 25px;

      .spacer {
        width: 50px;
        border-top: 3px dotted #444b5a;
      }

      .activeStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }

      .completedStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }
      .stepBlocks {
        background-color: inherit;
        border: #444b5a 2px solid;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .stepNumber {
          font-weight: 700;
          color: #444b5a;
          font-size: 25px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    input {
      height: 60px;
      background-color: #262830;
      border: 1px solid #17181a;
      margin: 10px 0px 15px 0px;
      color: #eff3ff;
      padding: 10px;
      width: 90%;
      margin-right: 10px;
    }

    div .input {
      display: flex;
      flex-direction: column;
    }

    .register {
width: calc(100% - 80px);
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 40px;
    }
  }

  .message {
display: flex;
    flex-direction: column;
    background-color: #444b5a;
    padding: 10px;
    color: #eff3ff;
    align-items: center;
    justify-items: space-around;
    font-size: 18px;
    margin-top: auto;

    span {
      text-align: center;
      font-weight: 400;
    }

    .header {
      margin-top: 10px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .link {
      margin-top: 10px;
      color: #b2c7ff;
    }

    ul {
      list-style-type: none;
      width: 80%;
      padding: 0;
    }

    li {
      display: flex;
      justify-content: space-between;
      line-height: 1.5em;
    }

    .iconError {
      color: #e93c2d;
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    height: auto;
    margin-top:auto;

    button {
      background-color: RGB(0, 132, 255);
      border: none;
      height: 60px;
      width: 460px;
      color: #eff3ff;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    button:hover {
      background-color: #0084ff;
    }
  }
  input.error {
    background-color: #4d2121 !important;
    border: #ad0000 solid 1px !important;
    color: #ad0000 !important;
  }
}

.inputs {
  margin-top:auto;
}

#saved {
  font-size: 2em;
  font-weight: 500;
  margin-top:30px;
  margin-bottom:10px;
}

.wrapper {
  margin-top: auto;
  display:flex;
  flex-direction: column;
  align-items: center;
}
</style>